import React from 'react'
import axios, { AxiosError } from 'axios'
import store from 'src/store'
import { useLogout } from 'src/store/hooks'
import { useNotification } from './notifications'
import { useTranslator } from './i18n'

export const useHttpClient = () => {
  const t = useTranslator('server-errors')
  const { bearerToken } = store.useBearerToken()
  const { sendNotification } = useNotification()
  const logout = useLogout()

  return React.useMemo(() => {
    const httpClient = axios.create({
      baseURL: process.env.REACT_APP_API,
      headers: {
        ...(bearerToken && { Authorization: `Bearer ${bearerToken}` })
      }
    })

    httpClient.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error instanceof AxiosError && error.response?.status == 401) {
          logout()
          sendNotification({ severity: 'danger', message: t`expired-session` })
        }
        return Promise.reject(error)
      }
    )

    return httpClient
  }, [bearerToken, logout, sendNotification, t])
}
