import { initReactI18next } from 'react-i18next'
import i18n, { Resource, ResourceLanguage } from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import en from './locales/en.json'
import es from './locales/es.json'
import ca from './locales/ca.json'

// Transforms a "plain language" resource into a "namespaced language".
//
// For example, this "plain language" resource:
//
// {
//   'accept': 'Accept',
//   'cancel': 'Cancel',
//   'errors.error-1': 'Error 1',
//   'errors.error-2': 'Error 1',
//   'errors.error-3': 'Error 1'
// }
//
// is transformed into this "namespaced language" resource:
//
// {
//   'default': {
//     'accept': 'Accept',
//     'cancel': 'Cancel'
//   },
//   'errors': {
//     'error-1': 'Error 1',
//     'error-2': 'Error 1',
//     'error-3': 'Error 1'
//   }
// }
const plain2namespacedLanguage = (lang: ResourceLanguage): Resource => {
  // obtains the "namespace" and the "term" from a key
  const parseKey = (key: string) => {
    const items = key.split('.')
    const len = items.length
    let namespace = 'default'
    let term = key

    if (len > 1) {
      const lastIndex = Math.min(2, len - 1)
      namespace = items.slice(0, lastIndex).join('.')
      term = items.slice(lastIndex).join('.')
    }

    return { namespace, term }
  }

  const translations: Resource = {}
  for (const [key, value] of Object.entries(lang)) {
    const { namespace, term } = parseKey(key)
    if (translations[namespace] === undefined) {
      translations[namespace] = {}
    }
    translations[namespace][term] = value
  }

  return translations
}

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'en',
    fallbackNS: 'default',
    resources: {
      en: plain2namespacedLanguage(en),
      es: plain2namespacedLanguage(es),
      ca: plain2namespacedLanguage(ca)
    },
    interpolation: {
      // react already safes from xss
      escapeValue: false
    },
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      caches: ['localStorage']
    },
    parseMissingKeyHandler: (key: string) => `[${key}]`
  })

export default i18n
