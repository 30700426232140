import { createGlobalStyle } from 'styled-components'
import { darken } from 'polished'
import { Theme } from './providers/theme'

/**
 * GlobalStyles component that applies global styles to the application.
 * @component
 */
export const GlobalStyles = createGlobalStyle<{ theme: Theme }>`
    body {
        .p-inputtext:enabled:focus {
            border-color: ${({ theme }) => theme?.primaryColor} !important;
            box-shadow: 0 0 0 0.2rem ${({ theme }) => theme?.primaryColor}12 !important;
        }

        .p-inputtext:enabled:hover {
            border-color: ${({ theme }) => theme?.primaryColor} !important;
        }

        a {
            color: ${({ theme }) => theme?.secondaryColor};
            &:hover {
              color: ${({ theme }) => theme?.secondaryColor && darken(0.1, theme.secondaryColor)};
            }
        }

        /* PrimeReact Checkbox */
        .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover) .p-checkbox-box {
            border-color: ${({ theme }) => theme?.primaryColor} !important;
        }
        .p-checkbox:not(.p-disabled):has(.p-checkbox-input:hover).p-highlight .p-checkbox-box {
            border-color: ${({ theme }) => theme?.primaryColor} !important;
            background:  ${({ theme }) => theme?.primaryColor} !important;
            color: #ffffff;
        }
        .p-checkbox:not(.p-disabled):has(.p-checkbox-input:focus-visible) .p-checkbox-box {
            border-color: ${({ theme }) => theme?.primaryColor} !important;
        }
        .p-checkbox.p-highlight .p-checkbox-box {
            border-color: ${({ theme }) => theme?.primaryColor} !important;
            background: ${({ theme }) => theme?.primaryColor} !important;
        }

        /* PrimeReact Radio Button */
         .p-radiobutton.p-highlight .p-radiobutton-box {
             background: ${({ theme }) => theme?.primaryColor} !important;
             border-color: ${({ theme }) => theme?.primaryColor} !important;
             border-width: 2px;
        }

        /* PrimeReact Steps */
        .p-steps .p-steps-item .p-menuitem-link {
            background-color: inherit;
            text-decoration-line: none;
        }
    }
`
