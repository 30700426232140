import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import { useVars } from 'src/store/hooks'
import { SpinnerProps } from './types'

const Spinner = styled(({ width = 32, height = 32, label, className }: SpinnerProps) => {
  const { domainTheme } = useVars()
  return (
    <div className={className}>
      <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 35 36">
        <circle cx="17.5" cy="18" r="16" strokeLinecap="round" strokeWidth="3" className="stroke-neutral-80" />
        <path
          strokeLinecap="round"
          strokeWidth="3"
          d="M17.5 2c8.837 0 16 7.163 16 16s-7.163 16-16 16c-4.26 0-8.133-1.666-11-4.381"
          className="stroke-bluetiful-50"
          style={{ stroke: domainTheme?.primaryColor }}
        />
      </svg>
      {label && <label>{label}</label>}
    </div>
  )
})`
  ${tw`inline-flex flex-col items-center gap-8`}

  svg {
    ${tw`animate-spin inline-block h-32 w-32`}
  }

  label {
    ${tw`text-neutral-50 whitespace-pre text-center`}
  }
`

export default Spinner
