import React from 'react'
import { PageLoaderProps } from './types'
import LoadingPage from './LoadingPage'

const PageLoader = ({ page: Page }: PageLoaderProps) => {
  return (
    <React.Suspense fallback={<LoadingPage />}>
      <Page />
    </React.Suspense>
  )
}

export default PageLoader
