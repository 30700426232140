import { AxiosInstance, AxiosRequestConfig } from 'axios'
import { CheckVoteHashResponse, FileUrl, ValidatedResponse } from './types'
import { ADMIN_API } from './constants'

/* Get key and url for uploading a file with encrypted vote results */
type GetFileUrlParams = { pollCode: string }
export const getVerificationFileUrl = async (http: AxiosInstance, params: GetFileUrlParams): Promise<FileUrl> => {
  const { pollCode } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/verification`)
  return res.data
}

/* Upload a json file with encrypted vote results */
type UploadFileParams = { file: File; url: string; headers: AxiosRequestConfig['headers'] }
export const uploadVerificationFile = async (http: AxiosInstance, params: UploadFileParams) => {
  const { file, url, headers } = params
  await http.put(url, file, { headers })
}

/* Delete a file */
type DeleteFileParams = { url: string }
export const deleteVerificationFile = async (http: AxiosInstance, params: DeleteFileParams) => {
  const { url } = params
  await http.delete(url)
}

/* Send a key of the uploaded file to the server for poll results verification */
type ValidateVoteParams = { body: { key: string }; pollCode: string }
export const validateVote = async (http: AxiosInstance, params: ValidateVoteParams): Promise<ValidatedResponse[]> => {
  const { body, pollCode } = params
  const res = await http.post(`${ADMIN_API}/poll/${pollCode}/verification`, body)
  return res.data
}

/* Checks if hash is valid and registered in the poll */
type CheckHashParams = { pollCode: string; hash: string }
export const checkVoteHash = async (http: AxiosInstance, params: CheckHashParams): Promise<CheckVoteHashResponse> => {
  const { pollCode, hash } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/bulletinboard/hash/${hash}`)
  return res.data
}
