import axios, { AxiosInstance } from 'axios'
import { ADMIN_API } from './constants'

export const getCognito = async (params: {
  pollCode: string
}): Promise<{
  pool_id: string
  client_id: string
  aws_region: string
}> => {
  const { pollCode } = params
  const res = await axios.get(`${ADMIN_API}/poll/${pollCode}/cognito`)
  return res.data
}

type GetTokenParams = {
  pollCode: string
  adminToken?: string
}
export const getToken = async (http: AxiosInstance, params: GetTokenParams) => {
  const { pollCode, adminToken } = params
  const res = await http.get(`${ADMIN_API}/poll/${pollCode}/token`, {
    params: { adminToken }
  })
  return res.data
}
