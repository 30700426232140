import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CloseNotificationAction, SetNotificationAction, State } from 'src/store/types'

export const useNotification = () => {
  const dispatch = useDispatch()
  const { open, message, severity, reason } = useSelector((state: State) => state.notification)

  const sendNotification = React.useCallback(
    (params: { message: string; severity?: State['notification']['severity']; reason?: any }) => {
      const { message, severity = 'info', reason } = params
      dispatch<SetNotificationAction>({ type: 'SET-NOTIFICATION', payload: { open: true, message, severity, reason } })
    },
    [dispatch]
  )

  const closeNotification = React.useCallback(() => {
    dispatch<CloseNotificationAction>({ type: 'CLOSE-NOTIFICATION' })
  }, [dispatch])

  return React.useMemo(
    () => ({
      open,
      message,
      severity,
      reason,
      sendNotification,
      closeNotification
    }),
    [open, message, severity, reason, sendNotification, closeNotification]
  )
}
