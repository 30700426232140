import { AxiosInstance } from 'axios'
import { ADMIN_API } from './constants'

export type Theme = {
  primaryColor: string
  primaryTextColor: string
  secondaryColor: string
  faviconUrl: string
  logoUrl: string
}

export const getDomainTheme = async (http: AxiosInstance): Promise<Theme> => {
  const res = await http.get(`${ADMIN_API}/theme`)
  return res.data
}
