import { useEffect, useState } from 'react'

export enum Browser {
  Firefox = 'Firefox',
  Chrome = 'Chrome',
  Safari = 'Safari',
  Edge = 'Edge',
  Opera = 'Opera',
  Unknown = 'Unknown'
}

export const useBrowser = (): Browser => {
  const [browser, setBrowser] = useState<Browser>(Browser.Unknown)

  useEffect(() => {
    const userAgent = window.navigator.userAgent.toLowerCase()

    if (userAgent.indexOf('firefox') > -1) {
      setBrowser(Browser.Firefox)
    } else if (userAgent.indexOf('chrome') > -1 && userAgent.indexOf('edg') === -1 && userAgent.indexOf('opr') === -1) {
      setBrowser(Browser.Chrome)
    } else if (userAgent.indexOf('safari') > -1 && userAgent.indexOf('chrome') === -1) {
      setBrowser(Browser.Safari)
    } else if (userAgent.indexOf('edg') > -1) {
      setBrowser(Browser.Edge)
    } else if (userAgent.indexOf('opr') > -1 || userAgent.indexOf('opera') > -1) {
      setBrowser(Browser.Opera)
    } else {
      setBrowser(Browser.Unknown)
    }
  }, [])

  return browser
}
