// Tries to parse a JSON object, otherwise it returns undefined
export const parseJson = <T = any>(data: string): T | undefined => {
  try {
    return JSON.parse(data)
  } catch {
    return undefined
  }
}

export const sleep = (seconds: number) => new Promise((resolve) => setTimeout(resolve, 1000 * seconds))

export const concat = (glue: string, ...args: (string | null | undefined)[]) => {
  return args
    .filter((x) => !!x)
    .map((x) => `${x}`)
    .join(glue)
}

/**
 * Checks if the current date is within the specified date range.
 * If no start date and end date are provided, it returns true.
 * @param startDate - The start date of the range (optional).
 * @param endDate - The end date of the range (optional).
 * @returns A boolean indicating whether the current date is within the specified range.
 */
export const isDatesInRange = (startDate?: string, endDate?: string) => {
  const now = new Date()

  if (!startDate && !endDate) {
    return true
  }

  const start = startDate ? new Date(startDate) : null
  const end = endDate ? new Date(endDate) : null

  const isValidStart = start instanceof Date && !isNaN(start.getTime())
  const isValidEnd = end instanceof Date && !isNaN(end.getTime())

  if (isValidStart && !isValidEnd) {
    return start <= now
  } else if (!isValidStart && isValidEnd) {
    return now <= end
  } else if (isValidStart && isValidEnd) {
    return start <= now && end >= now
  } else {
    return true
  }
}
