import React from 'react'

export type ContextProps = {
  leftHeaderButton?: React.ReactNode
  rightHeaderButton?: React.ReactNode
}

export const context = React.createContext<ContextProps>({
  leftHeaderButton: undefined,
  rightHeaderButton: undefined
})
