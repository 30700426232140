import { useEffect, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'
import tw from 'twin.macro'
import Header from './layouts/PageLayout/Header'

export const AWSWAFCaptchaModal = () => {
  const containerRef = useRef<HTMLDivElement>(null)
  const containerStyle = containerRef.current?.style.display

  useEffect(() => {
    containerRef?.current?.focus()
  }, [containerStyle])

  return createPortal(
    <div>
      <Header className="fixed top-4 inset-x-0 z-30 h-[68px] hidden" showProgressBar={false} id="captchaHeader" />
      <ModalContainer id="captchaModalContainer" ref={containerRef}>
        <div id="captchaModal">
          <div id="captchaForm" />
        </div>
      </ModalContainer>
    </div>,
    document.body
  )
}

const ModalContainer = styled.div`
  ${tw`absolute inset-0 top-104 z-20 bg-neutral-90 hidden`}

  #captchaModal {
    ${tw`hidden`}
  }
`
