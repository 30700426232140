import React from 'react'
import { useVars } from 'src/store/hooks'

const useCustomResource = (resourceName: string, fileName: string) => {
  const { poll, census } = useVars()
  return React.useCallback(() => {
    const VERSION = '0'
    if (!poll && !census) {
      return {
        [`default${resourceName}Url`]: '',
        [`domain${resourceName}Url`]: '',
        [`census${resourceName}Url`]: ''
      }
    }

    const baseUrl = poll?.url ?? census?.baseUrl ?? ''
    const url = new URL(poll?.url ?? census?.baseUrl ?? '')

    let subDomain = ''
    if (!poll?.domainCode) {
      const subdomainRegex = /^https:\/\/([^.]+)\./
      const match = subdomainRegex.exec(baseUrl)
      if (match?.[1]) {
        subDomain = match[1]
      }
    }

    const domainCode = poll?.domainCode || subDomain // ¡Should use || instead of ?? here because domainCode can be empty string!
    const censusCode = url.pathname.substring(1)

    const defaultUrl = new URL([url.origin, 'customization', 'default', fileName].join('/'))
    const domainUrl = new URL([url.origin, 'customization', domainCode, fileName].join('/'))
    const censusUrl = new URL([url.origin, 'customization', domainCode, censusCode, fileName].join('/'))

    defaultUrl.searchParams.append('v', VERSION)
    domainUrl.searchParams.append('v', VERSION)
    censusUrl.searchParams.append('v', VERSION)

    return {
      [`default${resourceName}Url`]: defaultUrl.href,
      [`domain${resourceName}Url`]: domainUrl.href,
      [`census${resourceName}Url`]: censusUrl.href
    }
  }, [poll, census, resourceName, fileName])
}

export const useCustomTheme = () => {
  const generateResourceUrls = useCustomResource('Theme', 'voting-theme.css')
  const generateFaviconUrls = useCustomResource('Favicon', 'favicon.ico')
  const generateLogoUrls = useCustomResource('Logo', 'logo.png')

  return React.useMemo(
    () => ({
      theme: generateResourceUrls(),
      favicon: generateFaviconUrls(),
      logo: generateLogoUrls()
    }),
    [generateResourceUrls, generateFaviconUrls, generateLogoUrls]
  )
}
