import React from 'react'
import Cookies from 'js-cookie'
import { useParams } from 'react-router-dom'

export const useCookies = () => {
  const { pollCode, censusId } = useParams()
  const getCookie = React.useCallback((name: string) => Cookies.get(name), [])

  const setCookie = React.useCallback(
    (name: string, value: string) => {
      Cookies.set(name, value, { expires: 1, path: `/${pollCode ?? censusId}` })
    },
    [pollCode, censusId]
  )
  const removeCookie = React.useCallback(
    (name: string) => {
      Cookies.remove(name, { expires: 1, path: `/${pollCode ?? censusId}` })
    },
    [pollCode, censusId]
  )

  return React.useMemo(
    () => ({
      getCookie,
      setCookie,
      removeCookie
    }),
    [getCookie, setCookie, removeCookie]
  )
}
