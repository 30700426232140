import React from 'react'
import { useTranslator } from 'src/hooks/i18n'
import Spinner from 'src/components/feedback/Spinner'
import PageLayout from 'src/layouts/PageLayout'

const LoadingPage = () => {
  const t = useTranslator()
  const [showLoading, setShowLoading] = React.useState(false)

  React.useEffect(() => {
    const id = setTimeout(() => setShowLoading(true), 750)

    return () => {
      clearTimeout(id)
    }
  }, [])

  if (!showLoading) {
    return null
  }

  return (
    <PageLayout>
      <div className="flex flex-col gap-16 items-center justify-center h-full">
        <Spinner />
        <span className="font-semibold">{t`loading-page`}</span>
      </div>
    </PageLayout>
  )
}

export default LoadingPage
