// routes [path: string, params?: Record<string, string> ]
type Home = ['home']
type EncryptedPollVerification = ['verification']
type PollResults = ['results']
type CheckHash = ['check-hash']

type Route = Home | EncryptedPollVerification | PollResults | CheckHash

export const route = (...[path]: Route): string => {
  if (path == 'home') {
    return '/'
  } else if (path == 'verification') {
    return '/verification'
  } else if (path == 'results') {
    return '/results'
  } else if (path == 'check-hash') {
    return '/check-hash'
  }

  return '/'
}
