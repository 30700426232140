import {
  useVars,
  useBearerToken,
  useUser,
  usePollStatus,
  useActiveQuestion,
  useEmitVote,
  useFetchActiveQuestion,
  useLoadPollFiles,
  useLoadZoomCredentials,
  useRemoteLink
} from './hooks'

export type { State, AddLoader, RemoveLoader } from './types'
export { default as store, initState, reducer } from './store'
export default {
  useVars,
  useBearerToken,
  useUser,
  usePollStatus,
  useActiveQuestion,
  useEmitVote,
  useFetchActiveQuestion,
  useLoadPollFiles,
  useLoadZoomCredentials,
  useRemoteLink
}
