import { AxiosInstance } from 'axios'
import { ADMIN_API } from './constants'

export type EmitVoteParams = {
  pollCode: string
  questionId: string
  body: {
    voter_id: string
    question_id: string
    agent_id: string
    answers: {
      id: string
      weight: string
      vote_points?: string
    }[]
  }[]
}
export const emitVote = async (http: AxiosInstance, params: EmitVoteParams) => {
  const { pollCode, questionId, body } = params
  await http.post(`${ADMIN_API}/poll/${pollCode}/question/${questionId}/vote`, body)
}

export type EmitEarlyVoteParams = {
  pollCode: string
  body: {
    voter_id: string
    question_id: string
    answers: {
      id: string
      weight: string
      vote_points?: string
    }[]
  }[]
}
export const emitEarlyVote = async (http: AxiosInstance, params: EmitEarlyVoteParams) => {
  const { pollCode, body } = params
  await http.post(`${ADMIN_API}/poll/${pollCode}/early/vote`, body)
}

export const emitDeferredVote = async (http: AxiosInstance, params: EmitEarlyVoteParams) => {
  const { pollCode, body } = params
  await http.post(`${ADMIN_API}/poll/${pollCode}/deferred/vote`, body)
}

export const validateProxyInstructionVote = async (http: AxiosInstance, params: EmitEarlyVoteParams) => {
  const { pollCode, body } = params
  const res = await http.post(`${ADMIN_API}/poll/${pollCode}/proxy/instruction/vote`, body)
  return res
}
