import { useNotification } from 'src/hooks/notifications'
import { useErrorParser } from 'src/hooks/errors'
import Toast from 'src/components/feedback/Toast'
import { AppNotificationsProps } from './types'

const AppNotifications = ({ className }: AppNotificationsProps) => {
  const notification = useNotification()
  const errorParser = useErrorParser()

  return (
    <Toast
      open={notification.open}
      onClose={notification.closeNotification}
      variant={notification.severity}
      message={errorParser(notification.reason) || notification.message}
      className={className}
    />
  )
}

export default AppNotifications
