import React from 'react'
import styled from 'styled-components'
import tw from 'twin.macro'
import cn from 'classnames'
import { ToastProps } from './types'

const icons = {
  info: <i className="fa-regular fa-circle-info fa-lg" aria-hidden="true" />,
  success: <i className="fa-regular fa-circle-check fa-lg" aria-hidden="true" />,
  warning: <i className="fa-regular fa-triangle-exclamation fa-lg" aria-hidden="true" />,
  danger: <i className="fa-regular fa-circle-xmark fa-lg" aria-hidden="true" />
}

const Toast = styled(
  ({
    open = true,
    variant = 'info',
    autoClose = false,
    autoCloseDuration = 5000,
    onClose,
    message,
    className
  }: ToastProps) => {
    const ref = React.useRef<HTMLDivElement>(null)
    const buttonRef = React.useRef<HTMLButtonElement>(null)
    const [visible, setVisible] = React.useState(open)

    const doClose = React.useCallback(() => {
      if (onClose) {
        onClose()
      } else {
        setVisible(false)
      }
    }, [onClose, setVisible])

    React.useEffect(() => {
      if (!open) {
        return
      }

      const elem = ref.current
      const buttonElem = buttonRef.current
      const onMouseDown = (e: MouseEvent) => {
        if (!(e.target instanceof HTMLElement) || buttonElem?.contains(e.target)) {
          return
        }
        if (!elem?.contains(e.target)) {
          onClose?.()
        }
      }
      window.addEventListener('mousedown', onMouseDown)
      return () => {
        window.removeEventListener('mousedown', onMouseDown)
      }
    }, [open, onClose])

    React.useEffect(() => {
      const timeoutId = setTimeout(() => {
        if (!autoClose) {
          return
        }
        doClose()
      }, autoCloseDuration)
      return () => {
        clearTimeout(timeoutId)
      }
    }, [open, variant, message, autoClose, autoCloseDuration, doClose])

    React.useEffect(() => {
      setVisible(open)
    }, [open])

    return (
      <div ref={ref} className={cn(className, variant, { open: onClose ? open : visible })}>
        <div>{icons[variant]}</div>
        <div className="message">{message}</div>
        <button ref={buttonRef} onClick={doClose}>
          <i className="fa-regular fa-close fa-2xl" aria-hidden="true" />
        </button>
      </div>
    )
  }
)`
  ${tw`
    absolute inset-x-[10px] bottom-[10px] z-20
    flex gap-12 items-center
    p-24
    rounded-m
    shadow-hard
    invisible
  `}

  &.open {
    ${tw`visible`}
  }

  .message {
    ${tw`mr-auto text-neutral-20 font-semibold`}
  }

  // Variants
  &.info {
    ${tw`bg-info-90 text-info-50`}
  }
  &.success {
    ${tw`bg-success-90 text-success-50`}
  }
  &.warning {
    ${tw`bg-warning-90 text-warning-50`}
  }
  &.danger {
    ${tw`bg-error-90 text-danger-50`}
  }
`

export default Toast
