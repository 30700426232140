import { useNavigate } from 'react-router-dom'
import 'src/assets/css/themes/lara/lara-light/indigo/theme.scss'
import Button from 'src/components/buttons/Button'
import notFound from 'src/assets/not-found.png'
import { useTranslator } from 'src/hooks/i18n'
import PageLayout from 'src/layouts/PageLayout'

const NotFoundPage = () => {
  const t = useTranslator('not-found')
  const navigate = useNavigate()

  return (
    <PageLayout>
      <div className="flex flex-col items-center py-48 md:pt-104 px-32">
        <img src={notFound} alt="Page not found" className="mb-32" />
        <h2 className="mb-12">{t`error-404`}</h2>
        <p className="text-l text-neutral-40 mb-12">{t`page-not-available`}</p>
        <Button outlined label={t`go-home`} onClick={() => navigate('/')} />
      </div>
    </PageLayout>
  )
}

export default NotFoundPage
