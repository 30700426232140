import { useVars } from 'src/store/hooks'
import { useLoader } from 'src/hooks/loaders'
import ProgressBar from 'src/components/feedback/ProgressBar'
import { AppLoadingProps } from './types'

const AppLoading = ({ className }: AppLoadingProps) => {
  const { loading } = useLoader()
  const { domainTheme } = useVars()

  return <ProgressBar loading={loading} className={className} theme={domainTheme} />
}

export default AppLoading
