import React from 'react'
import cn from 'classnames'
import Spinner from 'src/components/feedback/Spinner'
import AppNotifications from 'src/components/app/AppNotifications'
import Header from './Header'
import { PageLayoutProps } from './types'
import { context } from './context'

const PageLayout = ({
  auxiliaryFooter,
  pageLoading = false,
  leftHeaderButton,
  rightHeaderButton,
  showProgressBar = true,
  footer,
  children,
  className
}: PageLayoutProps) => {
  const contextVal = React.useMemo(
    () => ({
      leftHeaderButton,
      rightHeaderButton
    }),
    [leftHeaderButton, rightHeaderButton]
  )

  return (
    <context.Provider value={contextVal}>
      <Header className="fixed top-0 inset-x-0 z-10" showProgressBar={showProgressBar} />
      <div
        className={cn(
          className,
          'app-layout flex flex-col overflow-hidden bg-neutral-90 h-[100dvh] md:h-auto md:mb-32'
        )}
      >
        <Header />
        <div className="app-layout-body grow flex flex-col overflow-auto md:max-w-lg md:w-full md:self-center md:mt-32">
          <div className="p-24 md:px-0 h-full">
            {pageLoading ? (
              <div className="flex items-center justify-center h-full">
                <Spinner />
              </div>
            ) : (
              children
            )}
          </div>
        </div>
        {!pageLoading && auxiliaryFooter && (
          <div className="app-layout-footer flex items-stretch p-16 border-t border-neutral-80 md:hidden">
            {auxiliaryFooter}
          </div>
        )}
        {!pageLoading && footer && (
          <footer className="app-layout-footer flex flex-col items-stretch p-16 border-t border-neutral-80 md:max-w-lg md:w-full md:self-center md:border-0 md:p-0 md:pb-4">
            {footer}
          </footer>
        )}
      </div>
      <AppNotifications />
    </context.Provider>
  )
}

export default PageLayout
