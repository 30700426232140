import cn from 'classnames'
import styled from 'styled-components'
import tw from 'twin.macro'
import { Theme } from 'src/providers/theme'
import { ProgressBarProps } from './types'

const ProgressBar = ({ loading, className, theme }: ProgressBarProps) => {
  return (
    <StyledContainer className={className} theme={theme}>
      <div className={cn('progressBar', { loading })}>
        <div className="segment1" />
        <div className="segment2" />
      </div>
    </StyledContainer>
  )
}

const StyledContainer = styled.div<{ theme: Theme }>`
  @keyframes motion {
    from {
      width: 100%;
    }
    to {
      width: 0;
    }
  }

  .progressBar {
    ${tw`relative bg-bluetiful-50 w-full h-[3px] invisible`}
    background-color: ${({ theme }) => theme?.primaryColor}20;
  }

  .progressBar .segment1 {
    ${tw`bg-bluetiful-80 absolute left-0 h-[3px]`}
    background-color: ${({ theme }) => theme?.primaryColor};
  }

  .progressBar .segment2 {
    ${tw`bg-bluetiful-80 absolute right-0 h-[3px]`}
    background-color: ${({ theme }) => theme?.primaryColor};
  }

  .progressBar.loading {
    ${tw`visible`}

    .segment1 {
      animation: motion 2s infinite ease-out reverse;
    }

    .segment2 {
      animation: motion 2s infinite ease-out;
    }
  }
`

export default ProgressBar
