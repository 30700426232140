import tw from 'twin.macro'
import cn from 'classnames'
import styled from 'styled-components'
import store from 'src/store'
import { Browser, useBrowser } from 'src/hooks/browser'

export type HeaderWarningBannerProps = {
  bgColor?: string
  textColor?: string
  children: React.ReactNode
}

const HeaderWarningBanner = ({ bgColor, textColor, children }: HeaderWarningBannerProps) => {
  const browser = useBrowser()
  const { poll } = store.useVars()
  const isFirefox = browser === Browser.Firefox
  const isPollEncrypted = poll?.crypto?.type === 'PALLIER'

  if (!isFirefox || !isPollEncrypted) return null

  return (
    <StyledContainer className={cn('bg-error-50 text-white', bgColor, textColor)} role="alert" aria-live="assertive">
      <span>{children}</span>
    </StyledContainer>
  )
}

export default HeaderWarningBanner

const StyledContainer = styled.div`
  ${tw`flex justify-center items-center w-full p-16 font-semibold`}
`
