import styled from 'styled-components'
import { Button as PrimeButton, ButtonProps } from 'primereact/button'
import { Theme } from 'src/providers/theme'
import { useVars } from 'src/store/hooks'

const Button = ({ ...props }: ButtonProps) => {
  const { domainTheme } = useVars()
  if (!domainTheme) return <PrimeButton {...props} />

  return <StyledButton {...props} theme={domainTheme} />
}

export default Button

const StyledButton = styled(PrimeButton)<{ theme: Theme }>`
  background-color: ${({ theme, outlined, text }) => (outlined || text ? 'initial' : theme?.primaryColor)} !important;
  min-height: 40px;

  border-color: ${({ theme }) => theme?.primaryColor} !important;
  border-color: ${({ theme, outlined }) => (outlined ? theme?.secondaryColor : theme?.primaryTextColor)} !important;

  color: ${({ theme, outlined, text }) =>
    outlined || text ? theme?.secondaryColor : theme?.primaryTextColor} !important;

  &:hover {
    background-color: ${({ theme, outlined, text }) => {
      if (outlined) return theme?.primaryColor + '92'
      if (text) return 'initial'
      return theme?.primaryColor + '92'
    }} !important;

    color: ${({ theme, outlined, text }) => {
      if (outlined) return '#fff'
      if (text) return theme?.secondaryColor
      return theme?.primaryTextColor
    }} !important;
  }

  &:focus {
    box-shadow: 0 0 0 2px #fff,
      0 0 0 4px ${({ theme, outlined }) => (outlined ? theme?.secondaryColor : theme?.primaryColor + '72')} !important;
  }

  &:disabled {
    background-color: ${({ theme, outlined }) =>
      outlined ? theme?.secondaryColor + '72' : theme?.primaryColor + '72'} !important;
    border-color: ${({ theme, outlined }) =>
      outlined ? theme?.secondaryColor : theme?.primaryColor + '72'} !important;
  }
`
