import React from 'react'
import { Trans } from 'react-i18next'
import cn from 'classnames'
import store from 'src/store'
import { useTranslator } from 'src/hooks/i18n'
import logotype from 'src/assets/logotype.svg'
import AppLoading from 'src/components/app/AppLoading'
import HeaderWarningBanner from 'src/components/app/FirefoxBanner/HeaderWarningBanner'
import { context } from './context'

type HeaderProps = {
  className?: string
  showProgressBar?: boolean
  id?: string
}

const Header = ({ className, showProgressBar, id }: HeaderProps) => {
  const t = useTranslator('header')
  const { domainTheme } = store.useVars()
  const { logoUrl } = domainTheme ?? {}
  const { leftHeaderButton, rightHeaderButton } = React.useContext(context)
  const [loadLogoError, setLoadLogoError] = React.useState<boolean>(false)
  const [loadedLogoUrl, setLoadedLogoUrl] = React.useState<string>(logotype)

  React.useEffect(() => {
    if (logoUrl) {
      setLoadedLogoUrl(logoUrl)
    }
  }, [logoUrl])

  return (
    <header className={className} id={id}>
      <HeaderWarningBanner>
        <Trans
          i18nKey="header:firefox-performance-error"
          components={{
            a: (
              <a
                href={t`firefox-performance-link`}
                target="_blank"
                rel="noreferrer"
                className="underline underline-offset-2 decoration-2"
              />
            )
          }}
        />
      </HeaderWarningBanner>
      <div
        className={cn('flex items-center justify-between py-16 pl-16 border-b border-neutral-80 bg-white', {
          'pr-16': !rightHeaderButton
        })}
      >
        {leftHeaderButton ?? <span className={cn({ 'w-24': rightHeaderButton })}>&nbsp;</span>}

        <div className="flex items-center justify-center h-32 min-w-[133px]">
          <img
            src={loadedLogoUrl && !loadLogoError ? loadedLogoUrl : logotype}
            className={cn('object-contain', {
              'h-32 w-[133px]': loadedLogoUrl === logotype,
              'w-fit min-w-[133px] max-h-32': loadedLogoUrl !== logotype
            })}
            alt=""
            onError={() => setLoadLogoError(true)}
          />
        </div>

        {rightHeaderButton ?? <>&nbsp;</>}
      </div>
      {showProgressBar && <AppLoading />}
    </header>
  )
}

export default Header
